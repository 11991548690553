import './styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Resume from './Components/Resume';
import Console from './Components/Console';
import { useState } from 'react';
import VideoPlayer from './Components/VideoPlayer';
import LaunchpadIcon from './Components/LaunchPadIcon';
import LaunchPad from './Components/LaunchPad';

 export const App = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [showLaunchpad, setshowLaunchpad] = useState(false)
  const [zoomOut, setZoomOut] = useState(false)
  const [videoSrc, setVideoSrc] = useState("making-InfectDEAD")

  const onShowVideo = (filename) => {
    setVideoSrc(filename)
    setShowVideo(true)
  }

  const hideLaunchpad = () => {
    setZoomOut(true)
    setTimeout(()=>setshowLaunchpad(false), 1000)
  }

  return (
    <main>
      <BrowserRouter>
        <Console />
        {showLaunchpad && <LaunchPad className={ zoomOut ? 'zoomOut' : 'zoomIn'} onClick={hideLaunchpad} /> }
        {showVideo && (<VideoPlayer src={videoSrc} closeCallBack={()=>setShowVideo(false)} />)}
        <LaunchpadIcon onClick={() => {
          setZoomOut(false)
          setshowLaunchpad(true)
        }}/>
        <Routes>
          <Route path='/' Component={()=><></>} />
          <Route path="/resume" Component={()=><Resume  playVideo={(v)=>onShowVideo(v)}/>} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
