import React from "react"
import styled from "styled-components"
import FullScreenContainer from "./FullScreenContainer"
import DragableWindow from "./DragableWindow"

const VideoPlayer = (props) => {
    return (
        <FullScreenContainer zIndex="100">
            <StyledVideoContainer data-testid="video-player">
                <DragableWindow id="video" closable noBack closeCallBack={()=>setTimeout(()=>{
                        props.closeCallBack()
                    }, 500)}>
                        <video controls>
                            <source src={`videos/${props.src}.mp4`} type="video/mp4"/>
                        </video>
                </DragableWindow>
            </StyledVideoContainer>
        </FullScreenContainer>
    )
}

const StyledVideoContainer = styled.div`
    div.window {
        min-height: 0;
        .scroll {
            padding: 0;
            display: flex;
        }
    }
     video {
        width: 100%;
     }
}`

export default VideoPlayer