import React from "react";
import styled from "styled-components";

const FullScreenContainer = (props) => (
    <StyledFullScreenContainer {...props}/>
)

const StyledFullScreenContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    pointer-event: auto;
    ${props=>props.zIndex && `
    z-index: ${props.zIndex};`}
`
export default FullScreenContainer