// export function animateType(elem) {
//     const self = this
//     if (self.state.text.length > 0 && !self.interval) {
//         self.interval = setInterval(()=>{
//             let current = elem.firstChild.nodeValue
//             let text = self.state.text
//             elem.firstChild.nodeValue = current + text[0]
//             self.setState({
//                 text: text.split("").filter((val,i)=> i > 0).join("")
//             }, ()=> self.state.text.length === 0 && clearInterval(self.interval))
//         }, 150)
//     }
// }

export function dragElementMobile(elmnt) {
  const self = this
  elmnt.addEventListener("touchmove", handleMove);

  function handleMove(evt) {
    evt.preventDefault();
    const touches = evt.changedTouches;

    for (let i = 0; i < touches.length; i++) {
      let left = touches[i].pageX - Math.abs(touches[i].target.clientWidth - touches[i].pageX)
      let top = Math.abs(touches[i].target.clientHeight - touches[i].pageY)
      self.setState({
        touched: true,
        top,
        left
     })
    }
  }
  

}
export function dragElement(elmnt) {
    const self = this
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id)) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id).onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }
  
    function dragMouseDown(e) {
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
     self.setState({
        touched: true,
        top: (self.state.top - pos2) > 0 && self.state.top - pos2,
        left: !self.state.touched ? elmnt.offsetLeft : (self.state.left - pos1) > 0 && self.state.left - pos1
     })
    }
  
    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
}