import React from "react"
import styled, { keyframes } from "styled-components"

const LaunchPad = (props) => {
    return (
        <StyledLaunchpad {...props}>
            <Icon type={"html"} label="HTML"/>
            <Icon type={"css"} label="CSS" />
            <Icon type={"js"} label="Javascript" />
            <Icon type={"php"} label="PHP"/>
            <Icon type={"ts"} label="TypeScript" />
            <Icon type={"react"} label="React" />
            <Icon type={"node"} label="NodeJS" />
            <Icon type={"ng"} label="Angular" />
            <Icon type={"wp"} label="WordPress" />
            <Icon type={"mongo"} label="MongoDB" />
            <Icon type={"aws"} label="AWS" />
            <Icon type={"code"} label="VS Code" />
            <Icon type={"ps"} label="Photoshop" />
            <Icon type={"ae"} label="After Effects" />
            <Icon type={"ai"} label="Illustrator" />
            <Icon type={"pr"} label="Premiere Pro" />
            <Icon type={"blender"} label="Blender 3D" />
            <Icon type={"fcp"} label="Final Cut Pro" />
          </StyledLaunchpad>
    )
}

const ZoomIn = keyframes`
  0% {
    transform: scale(1.3) translateY(-25%);
  }
  100% {
    transform: scale(1) translateY(-50%);
  }
  `
const ZoomOut = keyframes`
  0% {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
  100% {
    transform: scale(1.3) translateY(-25%);
    opacity: 0;
  }
  `
  
  const FadeIn = keyframes`
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(15px);
  }
`

const StyledLaunchpad = styled.div`
  display: block;
  text-align: center;
  position: absolute;
  max-width: 600px;
  width: 100%;
  margin: auto;
  right: 0;
  left: 0;  
  z-index: 100;
  top: 50%;
  border-radius: 40px;
  padding: 15px 0;

  &.zoomIn {
    animation: ${ZoomIn} 0.8s ease forwards;
  }
  &.zoomOut {
    animation: ${ZoomOut} 0.8s ease forwards;
  }

  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    backdrop-filter: blur(15px);
    animation: ${FadeIn} 1s ease forwards;
    position: fixed;
    left: calc(-50vw + 50%);
    top: calc(-50vh + 50%);
    z-index: -1;
  }
  `
  
  const Icon = styled.div`
  display: inline-block;
  min-width: 68px;
  min-height: 68px;
  margin: 0.8rem 1rem;
  ${(props) => `background-image: url("/icons/${props.type}.png");`}
  background-size: cover;
  ${(props) => props.label ? `
  position: relative;
  margin: 1rem 0.8rem calc(1rem + 20px);
  &::after {
    content: '${props.label}';
    position: absolute;
    bottom: -30px;
    left: -20%;
    right: 0;
    color: #ffffff;
    width: 140%;
    font-size: 11pt;
  }
  ` : ''}
`

export default LaunchPad