import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Nav from "./Nav";
import DragableWindow from "./DragableWindow";

const Console = (props) => {

    const [text, setText] = useState(" Monita")

    useEffect(()=>{
        document.getElementsByClassName('type')[0].firstChild.nodeValue = ""
        
            const interval = setInterval(()=>{
                let elem = document.getElementsByClassName('type')[0]
                let current = elem.firstChild.nodeValue
                setText((prevText)=>{
                    if (prevText.length === 0) {
                        let parent = document.getElementsByClassName('type')[0]
                        let cursor = parent.querySelectorAll('.blink')[0]
                        parent.removeChild(cursor)
                        parent.appendChild(cursor)
                        return clearTimeout(interval)
                    }
                    elem.firstChild.nodeValue = current + prevText[0]
                    return prevText.split("").filter((val,i)=> i > 0).join("")
                })
            }, 150)
    
        return () => clearInterval(interval)
    },[])

    return (
        <StyledConsole text={text}>
            <DragableWindow id="taskbar">
                <div className='alex'>
                    <h1>
                        <span>{"{"}</span>
                            Alex 
                        <div className="type">
                            Monita
                            <b className="blink"/>
                            <span>{"}"}</span>
                        </div>
                    </h1>
                    <Nav />
                </div> 
            </DragableWindow>
        </StyledConsole>
    )
}

const StyledConsole = styled.div`
position: absolute;
width: 100%;
height: 100%;

.window {
    max-width: 600px;
    min-height: 600px;
    background: rgba(0,0,0,0.85);

    .alex {
        position: absolute;
        max-width: 400px;
        margin: 40px;

        h1 {
            color: #ffffff;
            margin: 0;
            font-size: 50pt;
            line-height: 50pt;
            font-weight: 900;
            text-transform: uppercase;

            div {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                position: relative;
                display: inline-block;
                ${(props)=>props.text && props.text.length < 7 && `left: 38px;`}
            }

            span {
                color: rgb(80, 255, 0);
                padding: 8px;
                font-weight: 400;
                position: relative;
                bottom: 5px;
              }
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .window {
        position: relative;
        margin:auto;
        left:0;
    }
}
@media screen and (max-width: 767px) {
    .window {
        .alex {
            margin: 0 30px;
            padding: 1rem 0;
        }
    }
}
@media screen and (max-width: 500px) {
    .window {
        .alex h1 {
            font-size: 40pt;
            line-height: 40pt;
        }
        a small {
            font-size: 12pt;
        }
    }
}
`

export default Console