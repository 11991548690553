import React from "react"
import styled from "styled-components"

const LaunchpadIcon = (props) => {
    return (
        <StyledLaunchpadIcon {...props}/>
    )
}

const StyledLaunchpadIcon = styled.div`
position: absolute;
left: 0;
right: 0;
bottom: 5px;
margin: auto;
width: 68px;
height: 68px;
cursor: pointer;
background-image: url("/icons/launchpad.png");
background-size: cover;
z-inedx: 100;
`

export default LaunchpadIcon