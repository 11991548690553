import React from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

const TaskBar = function(props) {
    const nav = useNavigate()
    const onClose = () => {
        setTimeout(()=>{
            nav("/")
        }, 500)
    }
    return (
        <StyledTaskBar className={props.closable ? 'closable' : ''} id={props.id}>
          <span 
            onClick={()=> {
                if (!props.closable) return
                props.closeCallBack()
                if (props.noBack) return
                onClose()
            }
          } >
            {props.closable && (
                <img src="/icons/close.svg" alt="close"/>
            )}
            </span>
          <span />
          <span />
        </StyledTaskBar>
    )
}

const StyledTaskBar = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: rgba(0,0,0,0.3);

    span, a {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin: 0 5px;
        background-color: #292d33;

        img {
            display: none;
            position: absolute;
            width: 11px;
            height: 11px;
            padding: 2px;
        }
        
        :first-child {
            background-color:#843939;
        }
    }

    &.closable:hover {
        span img, a img {
            display: block;
        }
    }

    &.closable span, &.closable a  {
        background-color: rgb(128, 128, 128);
        :first-child {
            cursor: pointer;
            background-color:rgb(255, 0, 0);
        }
    }
`

export default TaskBar