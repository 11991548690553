import React from "react";
import styled from "styled-components";
import TaskBar from "./TaskBar";
import { dragElement, dragElementMobile } from "../utils";
import { isMobile } from "react-device-detect";

export class DragableWindow extends React.Component  {
    constructor(props) {
        super(props)
        this.state = {
            closed: false,
            top: this.props.state ? this.props.state.top : 10,
            left: this.props.state ? this.props.state.left : 10,
        }
        this.dragElement = dragElement.bind(this)
        this.dragElementMobile = dragElementMobile.bind(this)
    }
    componentDidMount(){
        if (isMobile) {
            this.dragElementMobile(document.getElementById(this.props.id))
        } else {
             this.dragElement(document.getElementById(this.props.id))
        }
    }
    render(){
        return (
            <StyledDragableWindow
                data-testid="dragable-window"
                state={this.state}
                className={this.state.closed ? 'closed window' : 'window'}>
                <TaskBar 
                    closable={this.props.closable} 
                    noBack={this.props.noBack}
                    closeCallBack={()=>{
                        this.setState({closed: true})
                        if(this.props.closeCallBack) this.props.closeCallBack()
                    }}
                    id={this.props.id}
                />
                <div className="scroll">
                    {this.props.children}
                </div>
            </StyledDragableWindow>
        )
    }
}

const StyledDragableWindow = styled.div`
    display: block;
    position: absolute;
    left:${(props)=>props.state.left + 'px'};
    top:${(props)=>props.state.top + 'px'};
    width: 100%;
    max-width: 900px;
    min-height: 90vh;
    max-height: 90vh;
    border-radius: 10px;
    overflow: hidden;
    background: rgb(58, 58, 58);
    box-shadow: rgba(0, 0, 0, 0.39) 1px 1px 5px;
    border: 1px solid #373737;
    transform: scale(1);
    color: #fff;

    div {
        font-size: 16pt;
        font-weight: 200;
        line-height: 30pt;
        padding: 8pt 16pt;
    }

    .scroll {
        height: 100%;
        max-height: calc(90vh - 60px);
        overflow: scroll;
    }

    @media screen and (max-width: 1200px) {
        position: relative;
        margin:auto;
        max-width: 95%;
        left:0px;
    }

    &.closed {
        transition: all 0.5s ease-in;
        top: 100vh;
        transform: scale(0.3);
    }
`

export default DragableWindow