import React from "react"
import DragableWindow from "./DragableWindow"
import FullScreenContainer from "./FullScreenContainer"
import styled from "styled-components"

const Resume = (props) => {
    return (
        <FullScreenContainer>
            <DragableWindow id="bio" closable>
                <h1>Alex Monita</h1>
                <ResumeList className="no-style" data-testid="resume-list">
                    <li>
                        <h3>Redapple Digital Health Inc.<span> — Chief Developer / Cloud Systems Engineer</span></h3>
                        <div className="date">2020 - PRESENT</div>
                        <ul>
                            <li>Chief developer in the end-to-end development and launch of a tele-health solution, from its initial demo stage to a fully functional build. </li>
                            <li>Subsequently, supported deployment of ionic mobile versions on both the <a href="https://apps.apple.com/us/app/redapple-digital-health-inc/id6444813610" target="_blank" rel="noreferrer" title="Redapple Digital Health App">Apple App</a> and <a href="https://play.google.com/store/apps/details?id=com.redappleapp.app" target="_blank" rel="noreferrer" title="Redapple Digital Health App">Google Play</a> stores.</li>
                            <li>Implemented a high availability and highly resilient cloud architecture, ensuring that the system operates seamlessly with minimal downtime and meets the demands of the organization.</li>
                            {/* <li>Leveraged AWS services to automate infrastructure provisioning</li> */}
                            <li>Successfully implemented an AWS cloud CI/CD pipeline, streamlining the software delivery process and enhancing efficiency.</li>
                        </ul>
                    </li>
                    <li>
                        <h3>Walkthrough Productions<span> — Lead Web Application Developer / Video Editor</span></h3>
                        <div className="date">2016 - 2020</div>
                        <ul>
                            <li>Mobilized the research, development, and production of a flagship <a href="https://www.selectsouthbay.com" target="_blank" rel="noreferrer" text="https://selectsouthbay.com" title="https://selectsouthbay.com">AngularJS</a> website with custom PHP plugin to integrate MLS <a href="https://www.selectsouthbay.com/property/?ref=alex" target="_blank" rel="noreferrer" title="Working example">property feed.</a></li>
                            <li>Designed and implemented internal process tools utilizing PHP / React to automate administrative functions </li>
                            <li>Initiated an internal networked video editing system to allow for simultaneous editing access to all projects.</li>
                            <li>Leveraged Eventbrite API to deploy custom ticket management system for virtual event requiring limited access based on user IP.</li>
                            <li>Provided video editing services for clients.</li>
                        </ul>
                    </li>
                    <li>
                        <h3>Boys and Girls Clubs of the Los Angeles Harbor<span> — Website Developer / Digital Media Instructor</span></h3>
                        <div className="date">2005 -2016</div>
                        <ul>
                            <li>Led award-winning <span className="video" onClick={()=>props.playVideo("2014-vr-presentation")} title="Arts Academy Presentation">multimedia studio department</span></li>
                            <li>Designed and developed internal and external websites for the parent organization, utilizing a range of skills and knowledge related to web development and design.</li>
                            <li>Led a team of student members in the <span className="video" onClick={()=>props.playVideo("making-InfectDEAD")} title="Making of InfectDEAD">development and shipping</span> of a video game for android-based console <a href="https://archive.org/details/ouya_com.SubcultureGames.InfectDEAD_1.0" target="_blank" rel="noreferrer" title="OUYA Game Archive">(OUYA).</a></li>
                            <li>Developed a web-based training and tutorial platform to aid expansion of a college preparedness program to other club houses, securing funding via the <a href="https://everychildfoundation.org/wp-content/uploads/EverychildNewsletterSpring2011.pdf" target="_blank" rel="noreferrer" title="https://everychildfoundation.org">EveryChild foundation.</a></li>
                        </ul>
                    </li>
                </ResumeList>
            </DragableWindow>
        </FullScreenContainer>
    )
}
const ResumeList = styled.ul`
    &.no-style {
        list-style: none;
        padding-left: 0;
    }
    font-size: 16pt;
    ul {
        list-style: disc;
        line-height: 20pt;
        li {
            margin-bottom: 10pt;
        }
    }
    h3 {
        font-size: inherit;
        font-weight: 700;
        line-height: 18pt;
        margin: 16pt 0 0 0;
        span {
            font-size: 12pt;
            font-weight: 400;
        }
    }
    .date {
        font-size: 12pt;
        font-weight: 300;
        font-style: italic;
        padding: 0 0 10px;
    }
    a, a:hover, a:visited {
        display: inline-flex;
        position: relative;
        color: yellow;
        margin-right: 17px;
        &::after {
            display: block;
            content: "";
            background-image: url("https://img.icons8.com/?size=512&id=60664&format=png");
            background-size: contain;
            filter: invert(1);
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0px;
            right: -17px;
        }
    }
    .video {
        display: inline-flex;
        position: relative;
        margin-right: 28px;
        cursor: pointer;
        text-decoration: underline;
        &::after {
            display: block;
            content: "";
            background-image: url("https://cdn.onlinewebfonts.com/svg/img_349409.png");
            background-size: contain;
            filter: invert(1);
            width: 20px;
            height: 15px;
            position: absolute;
            top: 9px;
            right: -26px;
        }
    }
    @media screen and (max-width: 767px) {
        .date {
            padding: 0;
        }
        ul {
            line-height: 19pt;
            font-size: 14pt;
            padding-left: 12pt;
        }
    }
`
export default Resume